<template>
  <div class="flatpickr">
    <flat-pickr
      :style="{ width: width + 'px' }"
      v-model="dateValue"
      :config="dateConfig"
      :placeholder="placeholder"
      :name="name"
    ></flat-pickr>
    <i
      data-toggle
      class="date-i sdnm-date_selection"
      v-if="clear"
      v-show="dateValue === ''"
    ></i>
    <i data-toggle class="date-i sdnm-date_selection" v-if="!clear"></i>
    <i
      data-clear
      v-if="clear"
      class="date-i sdnm-error_circular"
      v-show="dateValue !== ''"
      @click="clearDate"
    ></i>
  </div>
</template>

<script>
/**
 * @module components/flatpickr
 * @desc 传蔬农贸版 选择日期控件
 * @version 0.0.1
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-09-28
 * @copyright 2021
 */
import { event } from "../modules/EVENTs";
import { getDate } from "../modules/utils";
import flatPickr from "vue-flatpickr-component"; //引入flatpickr组件
import "flatpickr/dist/flatpickr.css"; //引入他的css样式
import { Mandarin } from "flatpickr/dist/l10n/zh.js"; //引入普通话语言包

// const zh = require("flatpickr/dist/l10n/zh.js").default.zh;

export default {
  name: "flatpickr",
  props: {
    config: {
      type: Object,
      default: function() {
        return {};
      },
    },
    mode: {
      type: String,
      default: "range",
    },
    placeholder: {
      type: String,
      default: "                    --至--",
    },
    name: {
      type: String,
      default: "date-time",
    },
    clear: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 200,
    },
    cleanDate: {
      type: Boolean,
      default: false,
    }, // 清空时间属性
    getDateCallback: {},
    defaultVal: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dateValue: "",
      start: "",
      end: "",
      dateConfig: {
        wrap: true,
        // locale: zh,
        locale: Mandarin,
        dateFormat: "Y-m-d",
        maxDate: getDate(),
        onClose: (el) => {
          if (this.mode === "range") {
            this.rangeClose(el);
          } else if (this.mode === "single") {
            this.singleClose(el);
          }
        },
      },
    };
  },
  watch: {
    dateValue(newvalue, oldValue) {
      if (newvalue === "") {
        if (this.mode === "range") {
          this.rangeWatch();
        }
        // 单选清空时间也应该回传
        else {
          this.start = "";
          this.end = "";
          this.emitData();
        }
      }
    },
    defaultVal(newvalue, oldValue) {
      this.dateValue = newvalue;
    },
    cleanDate(newValue, oldValue) {
      if (newValue) {
        this.dateValue = "";
      }
    },
  },
  methods: {
    /**
     * 向父组件提供数据
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-25
     */
    emitData() {
      let dateObj = {
        start: this.start,
        end: this.end,
      };
      if (typeof this.getDateCallback === "undefined") {
        event.$emit("ss-flatpickr", dateObj, this.name);
      } else {
        this.getDateCallback(dateObj, this.name);
      }
    },
    /**
     * 清除时间
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-12-06
     */
    clearDate() {
      this.dateValue = "";
    },
    /**
     * 时间范围的关闭方法
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-25
     * @param  {Array}   el
     */
    singleClose(el) {
      if (el.length === 1) {
        this.start = getDate(el[0]);
        this.end = "";
        this.emitData();
      } else {
        this.dateValue = "";
      }
    },
    /**
     * 时间范围的关闭方法
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-25
     * @param  {Array}   el
     */
    rangeClose(el) {
      if (el.length === 2) {
        this.start = getDate(el[0]);
        this.end = getDate(el[1]);
        this.emitData();
      } else {
        this.dateValue = "";
      }
    },
    rangeWatch() {
      if (!this.clear) {
        this.start = getDate();
        this.end = getDate();
      } else {
        this.start = "";
        this.end = "";
      }
      this.emitData();
    },
  },
  created() {
    if (this.defaultVal !== "") {
      this.dateValue = this.defaultVal;
    }
    for (let c in this.config) {
      if (this.dateConfig.hasOwnProperty(c)) {
        if (this.config[c] !== this.dateConfig[c]) {
          this.dateConfig[c] = this.config[c];
        }
      } else {
        this.dateConfig[c] = this.config[c];
        if (c === "defaultDate") {
          this.dateValue = this.config[c][0] + "至" + this.config[c][1];
        }
      }
    }
    this.dateConfig.mode = this.mode;
  },
};
</script>

<style lang="less">
// @import (reference) "~less/base/utils.less";
// @import (reference) "~less/base/global.less";
@import url("../less/base/utils.less");
@import url("../less/base/global.less");
@import url("../less/base/font.less");
.flatpickr {
  .dib();
}
i.date-i {
  .mgl(-30px);
  .mgr(15px);
  .fc(16px, #aeb5bf);
  .dib();
  .ta-c();
  .cs();
  vertical-align: sub;
}
</style>
